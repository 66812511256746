import { __decorate, __metadata } from "tslib";
import { ArcElement, BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip } from 'chart.js';
import { MatrixController, MatrixElement } from 'chartjs-chart-matrix';
import annotationPlugin from 'chartjs-plugin-annotation';
import { createTypedChart } from 'vue-chartjs';
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import BaseView from '~/nasa_ui/base/BaseView';
const CustomMatrixChartForRisks = createTypedChart('matrix', MatrixElement);
ChartJS.register(annotationPlugin, BarElement, MatrixController, MatrixElement, Title, Tooltip, Legend, ArcElement, BarElement, CategoryScale, LinearScale);
const backgroundColorPlugin = {
    id: 'riskMatrixChartBackground',
    beforeDraw: (chart, args, options) => {
        const leftMargin = chart.chartArea.left || 0;
        const topMargin = chart.chartArea.top || 0;
        const chartWidth = chart.chartArea.right - leftMargin;
        const chartHeight = chart.chartArea.bottom - topMargin;
        const rectangleWidth = (chartWidth * 0.97) / 5;
        const rectangleHeight = (chartHeight * 0.97) / 5; // Adjust the aspect ratio as needed
        const xMargin = rectangleWidth * 0.03;
        const yMargin = rectangleHeight * 0.03;
        const lowRiskColor = '#99e9b3';
        const moderateRiskColor = '#ffef99';
        const highRiskColor = '#efb2b2';
        const colors = [
            lowRiskColor,
            moderateRiskColor,
            highRiskColor,
            highRiskColor,
            highRiskColor,
            lowRiskColor,
            moderateRiskColor,
            moderateRiskColor,
            highRiskColor,
            highRiskColor,
            lowRiskColor,
            moderateRiskColor,
            moderateRiskColor,
            moderateRiskColor,
            highRiskColor,
            lowRiskColor,
            lowRiskColor,
            moderateRiskColor,
            moderateRiskColor,
            moderateRiskColor,
            lowRiskColor,
            lowRiskColor,
            lowRiskColor,
            lowRiskColor,
            moderateRiskColor
        ];
        const { ctx } = chart;
        ctx.save();
        ctx.globalCompositeOperation = 'destination-over';
        for (let i = 0; i < 5; i++) {
            for (let j = 0; j < 5; j++) {
                const colorIndex = i * 5 + j;
                const color = colors[colorIndex];
                const x = leftMargin + j * (rectangleWidth + xMargin) + (j === 0 ? 0 : xMargin);
                const y = topMargin + i * (rectangleHeight + yMargin) + (i === 0 ? 0 : yMargin);
                ctx.fillStyle = color;
                ctx.fillRect(x, y, rectangleWidth, rectangleHeight);
            }
        }
        ctx.restore();
    }
};
let ChartRiskMatrix = class ChartRiskMatrix extends Mixins(BaseView) {
    // don't register this on the ChartJS instance
    // it will affect all matrix charts
    chartRef = null;
    backgroundColorPlugin = backgroundColorPlugin;
    // these are passed in as props
    // then passed to CustomMatrixChartForRisks component we create
    // to override chartOptions passed in we change the internal options
    // that get passed as prop to CustomMatrix options
    annotations;
    chartData;
    chartOptions;
    height;
    width;
    options = {
        maintainAspectRatio: false,
        responsive: true,
        scales: {
            x: {
                labels: ['1', '2', '3', '4', '5'],
                min: 1,
                max: 5,
                offset: true,
                reverse: false,
                grid: {
                    display: false
                },
                ticks: {
                    stepSize: 1
                }
            },
            y: {
                min: 1,
                max: 5,
                offset: true,
                reverse: false,
                grid: {
                    display: false
                },
                ticks: {
                    stepSize: 1
                }
            }
        },
        plugins: {
            annotation: {
                annotations: [
                    {
                        backgroundColor: 'white',
                        type: 'ellipse',
                        xMax: 4.25,
                        xMin: 3.75,
                        yMax: 5.25,
                        yMin: 4.75
                    }
                ]
            },
            legend: {
                display: true
            },
            tooltip: {
                callbacks: {
                    title() {
                        return '';
                    },
                    label(context) {
                        const v = context.dataset.data[context.dataIndex];
                        return [v.v];
                    }
                }
            },
            riskMatrixChartBackground: {}
        }
    };
    get computedStyle() {
        if (!this.width && !this.height) {
            return '';
        }
        if (this.width && this.height) {
            return `width: ${this.width}; height: ${this.height}; `;
        }
        if (this.width && !this.height) {
            return `width: ${this.width};`;
        }
        if (!this.width && this.height) {
            return `height: ${this.height};`;
        }
    }
    // mounted() {
    // TODO - fix chartRef, this was recommended but ref seems undefined?
    //   this.$set(this, 'chartRef', this.$refs.customMatrix);
    // }
    onAnnotationsChange() {
        this.options.plugins.annotation.annotations = this.annotations;
        // chartref is undefined, this does nothing right now
        // open issue https://github.com/apertureless/vue-chartjs/issues/1012
        // this.chartRef.update();
    }
    onOptionsPropChange() {
        this.options = this.chartOptions;
    }
};
__decorate([
    Prop({
        default: () => {
            return [];
        }
    }),
    __metadata("design:type", Object)
], ChartRiskMatrix.prototype, "annotations", void 0);
__decorate([
    Prop({
        default: () => {
            return {
                datasets: [
                    {
                        data: []
                    }
                ]
            };
        }
    }),
    __metadata("design:type", Object)
], ChartRiskMatrix.prototype, "chartData", void 0);
__decorate([
    Prop({}),
    __metadata("design:type", Object)
], ChartRiskMatrix.prototype, "chartOptions", void 0);
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", String)
], ChartRiskMatrix.prototype, "height", void 0);
__decorate([
    Prop({
        type: String
    }),
    __metadata("design:type", String)
], ChartRiskMatrix.prototype, "width", void 0);
__decorate([
    Watch('annotations'),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], ChartRiskMatrix.prototype, "onAnnotationsChange", null);
__decorate([
    Watch('chartOptions', { immediate: true, deep: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", []),
    __metadata("design:returntype", void 0)
], ChartRiskMatrix.prototype, "onOptionsPropChange", null);
ChartRiskMatrix = __decorate([
    Component({
        components: {
            CustomMatrixChartForRisks
        }
    })
], ChartRiskMatrix);
export default ChartRiskMatrix;
