var _a, _b, _c, _d, _e;
import { __decorate, __metadata } from "tslib";
import { BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, TimeScale, Title, Tooltip } from 'chart.js';
import 'chartjs-adapter-date-fns/dist/chartjs-adapter-date-fns.esm.js';
import { format, isAfter, isBefore, subYears } from 'date-fns';
import { Bar } from 'vue-chartjs';
import { Component, Mixins, Prop, Vue, Watch } from 'vue-property-decorator';
import colors from 'vuetify/lib/util/colors';
import BaseView from '~/nasa_ui/base/BaseView';
import { isNullOrUndefined } from '~/nasa_ui/utils';
Vue.component('BarChart', Bar);
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, TimeScale);
// this prevents error calling Tooltip._positionChanged and handleEvent with undefined
Tooltip.positioners.custom = (elements, eventPosition) => {
    return {
        x: eventPosition.x,
        y: eventPosition.y
    };
};
let ChartTimeline = class ChartTimeline extends Mixins(BaseView) {
    ChartJS = ChartJS;
    chartRef = 'trend';
    labelGrouping = [];
    chartData = {
        labels: [],
        datasets: {}
    };
    barThickness;
    minBarLength;
    parentData;
    // default two years ago
    xScaleMinDate;
    //default end of 2032
    xScaleMaxDate;
    xScaleTime;
    hasDataChanged(newData, oldData) {
        if (!oldData) {
            return true;
        }
        if (newData.length !== oldData.length) {
            return true;
        }
        for (let i = 0; i < newData.length; i++) {
            const newEntry = Object.entries(newData[i]);
            const oldEntry = Object.entries(oldData[i]);
            if (newEntry.length !== oldEntry.length) {
                return true; // If number of properties is different, consider it changed
            }
            for (const [key, value] of newEntry) {
                if (value !== oldData[i][key]) {
                    return true; // If any property value is different, consider it changed
                }
            }
        }
        return false;
    }
    mounted() {
        this.validateDateRanges();
    }
    transformData(data) {
        if (!this.parentData) {
            return;
        }
        let datasets = [];
        // Example transformation
        datasets = this.parentData
            .sort((a, b) => a.startDate.getTime() - b.startDate.getTime())
            .map((event) => {
            let start = event.startDate.getTime();
            let end = event.endDate.getTime();
            let stack = undefined;
            let firstStackEntry = false;
            if (this.labelGrouping[event.id] === undefined) {
                stack = { Stack: 'Stack0', LastDate: event.endDate };
                this.labelGrouping[event.id] = [stack];
                firstStackEntry = true;
            }
            else {
                this.labelGrouping[event.id].forEach((item, index) => {
                    if (stack === undefined && item.LastDate.getTime() <= event.startDate.getTime()) {
                        stack = { ...item };
                        item.LastDate = event.endDate;
                    }
                });
                if (stack === undefined) {
                    const stackIndex = this.labelGrouping[event.id].length;
                    stack = { Stack: 'Stack' + stackIndex, LastDate: event.endDate };
                    this.labelGrouping[event.id].push(stack);
                    firstStackEntry = true;
                }
            }
            const data = [];
            if (!firstStackEntry) {
                start -= stack.LastDate.getTime();
                end -= stack.LastDate.getTime();
            }
            const index = this.labels.find((label) => label[0] === event.id);
            if (index) {
                data[this.labels.indexOf(index)] = [
                    start,
                    end,
                    format(event.startDate, 'yyyy-MM-dd') + ' - ' + format(event.endDate, 'yyyy-MM-dd')
                ];
            }
            return {
                label: event.name,
                data: data,
                // this modifies the bar's width on horizontal bar
                minBarLength: this.minBarLength,
                // this modifies the bar's height on horizontal bar
                barThickness: this.barThickness,
                skipNull: true,
                backgroundColor: this.eventColors[this.eventNames.indexOf(event.name)],
                stack: event.id + '_' + stack.Stack
            };
        });
        return {
            labels: this.labels,
            datasets: datasets
        };
    }
    validateDateRanges() {
        const isPastXScaleMax = (date) => {
            return isAfter(date, this.xScaleMaxDate);
        };
        const isBeforeXScaleMin = (date) => {
            return isBefore(date, this.xScaleMinDate);
        };
        this.parentData.forEach((dateRange, index) => {
            const startDate = dateRange.startDate;
            const endDate = dateRange.endDate;
            if (isBeforeXScaleMin(startDate)) {
                console.log('ChartTimeline: Start date out of range');
            }
            if (isPastXScaleMax(endDate)) {
                console.log('ChartTimeline: End date out of range');
            }
        });
    }
    get chartOptions() {
        return {
            indexAxis: 'y',
            // emits id when bar clicked
            onClick: (event, chartElements) => {
                if (chartElements.length > 0 && this.chartData && this.chartData.datasets) {
                    const clickedElementIndex = chartElements[0].index;
                    const id = this.labels[clickedElementIndex][0]; // Assuming labels correspond to id
                    this.$emit('bar-clicked', id);
                }
            },
            plugins: {
                tooltip: {
                    callbacks: {
                        title: () => '',
                        afterBody: (items) => this.chartData &&
                            this.chartData.datasets[items[0].datasetIndex] &&
                            this.chartData.datasets[items[0].datasetIndex].data &&
                            items[0] &&
                            !isNullOrUndefined(items[0]?.dataIndex) &&
                            this.chartData.datasets[items[0].datasetIndex].data[items[0]?.dataIndex] &&
                            !isNullOrUndefined(this.chartData.datasets[items[0].datasetIndex].data[items[0]?.dataIndex])
                            ? this.chartData.datasets[items[0].datasetIndex].data[items[0].dataIndex][2]
                            : [],
                        label: (item) => this.chartData.datasets[item.datasetIndex].label
                    },
                    position: 'custom'
                },
                legend: {
                    display: false
                },
                title: {
                    display: false,
                    text: 'Timeline'
                },
                datalabels: {
                    color: 'black',
                    anchor: 'start',
                    align: 'right',
                    display: (context) => {
                        return context.dataset.data[context.dataIndex] !== null ? 'auto' : false;
                    },
                    font: function (context) {
                        const width = context.chart.width;
                        const size = width / 100;
                        return {
                            weight: 'bold',
                            size: size
                        };
                    }
                }
            },
            resizeDelay: 20,
            responsive: true,
            maintainAspectRatio: false,
            scales: {
                x: {
                    title: {
                        display: false
                    },
                    min: Math.min(this.xScaleMinDate.getTime()),
                    max: Math.max(this.xScaleMaxDate.getTime()),
                    type: 'time',
                    time: this.xScaleTime,
                    stacked: true
                },
                y: {
                    stacked: true,
                    title: {
                        display: false
                    },
                    ticks: {
                        display: false
                    }
                }
            }
        };
    }
    get eventColors() {
        return Object.keys(colors).map((color) => colors[color].base);
    }
    get eventNames() {
        return [...new Set(this.parentData.map((event) => event.name))];
    }
    get labels() {
        return [
            ...new Set(this.parentData.map((event) => [
                event.id,
                `${format(event.startDate, 'yyyy-MM-dd')} - ${format(event.endDate, 'yyyy-MM-dd')}`
            ]))
        ];
    }
    get transformedChartData() {
        return this.transformData(this.parentData);
    }
    onDataChanged(newData, oldData) {
        if (this.hasDataChanged(newData, oldData)) {
            this.chartData = this.transformData(newData);
        }
    }
};
__decorate([
    Prop({
        default: 25,
        type: Number
    }),
    __metadata("design:type", Number)
], ChartTimeline.prototype, "barThickness", void 0);
__decorate([
    Prop({
        default: 40,
        type: Number
    }),
    __metadata("design:type", Number)
], ChartTimeline.prototype, "minBarLength", void 0);
__decorate([
    Prop({ required: true, type: Array }),
    __metadata("design:type", Array)
], ChartTimeline.prototype, "parentData", void 0);
__decorate([
    Prop({
        default: subYears(new Date(), 2),
        type: Date
    }),
    __metadata("design:type", typeof (_a = typeof Date !== "undefined" && Date) === "function" ? _a : Object)
], ChartTimeline.prototype, "xScaleMinDate", void 0);
__decorate([
    Prop({
        default: new Date(2032, 11, 31),
        type: Date
    }),
    __metadata("design:type", typeof (_b = typeof Date !== "undefined" && Date) === "function" ? _b : Object)
], ChartTimeline.prototype, "xScaleMaxDate", void 0);
__decorate([
    Prop({
        default: {
            unit: 'year',
            displayFormats: {
                year: 'yyyy' // Format for displaying the year
            }
        },
        type: Object
    }),
    __metadata("design:type", typeof (_c = typeof Object !== "undefined" && Object) === "function" ? _c : Object)
], ChartTimeline.prototype, "xScaleTime", void 0);
__decorate([
    Watch('parentData', { immediate: true, deep: true }),
    __metadata("design:type", Function),
    __metadata("design:paramtypes", [typeof (_d = typeof Array !== "undefined" && Array) === "function" ? _d : Object, typeof (_e = typeof Array !== "undefined" && Array) === "function" ? _e : Object]),
    __metadata("design:returntype", void 0)
], ChartTimeline.prototype, "onDataChanged", null);
ChartTimeline = __decorate([
    Component
], ChartTimeline);
export default ChartTimeline;
