<template><section><h1>Change Log</h1>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/compare/v11.0.1...v11.0.2">11.0.2</a> (2025-03-20)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> update to v52.3.1 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/40a7b20fe10299ec8d996f8216a2ef1a2edd0997">40a7b20</a>)</li>
<li><strong>Schema:</strong> update to v52.3.2 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/27da1a863a88a01a3836ec93c5126cbb01a4071c">27da1a8</a>)</li>
<li><strong>Schema:</strong> update to v52.3.3 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/65e8546e2b13fb124d957e06ff28ca791b6738c6">65e8546</a>)</li>
<li><strong>Schema:</strong> update to v52.4.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/455b1f93087780675f55c691b8a3670c564ab7e2">455b1f9</a>)</li>
<li><strong>Schema:</strong> update to v52.4.1 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/d5f952172fa9a54e942616f6332b4747464fce28">d5f9521</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/compare/v11.0.0...v11.0.1">11.0.1</a> (2025-03-14)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/f273d1435ac4a4a6b58767e1f79b645337e67fa8">f273d14</a>)</li>
<li><strong>Schema:</strong> update to v52.2.1 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/c5251e8bbeda298d3b5a7514dae8384ae030652d">c5251e8</a>)</li>
<li><strong>Schema:</strong> update to v52.3.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/59c5d930db608b108e2c426a992787456deef19e">59c5d93</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/compare/v10.13.29...v11.0.0">11.0.0</a> (2025-03-12)</h1>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> update to v52.2.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/8b4c8c2d17a5cbfc2a52dc89e2d9998b7b49372b">8b4c8c2</a>)</li>
</ul>
<h3>Features</h3>
<ul>
<li><strong>Progress:</strong> ever forward. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/c8f4e36bd56a9bae6a9a5e00ee31f9ff4603916f">c8f4e36</a>)</li>
</ul>
<h3>BREAKING CHANGES</h3>
<ul>
<li><strong>Progress:</strong> updated progress indictors throughout the app. no obvious visual change expected</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/compare/v10.13.28...v10.13.29">10.13.29</a> (2025-03-11)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> cleanup (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/a53f68d1e5e02309c1910adb1a79fac6f1cf46b2">a53f68d</a>)</li>
<li><strong>COSMIC:</strong> Contracts update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/574b3265b3518f370c770ed6c118516a750c28b2">574b326</a>)</li>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/dde3c02c1d406528c1a0e4d3417d56d1eaeb054f">dde3c02</a>)</li>
<li><strong>Schema:</strong> update to v51.1.1 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/0f115b31e1b2a4bc998c43615a0cae34194cbf26">0f115b3</a>)</li>
<li><strong>Schema:</strong> update to v51.1.2 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/884135041557a885de40232879c25c1862de134c">8841350</a>)</li>
<li><strong>Schema:</strong> update to v51.1.3 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/563a802dd37bb77467d07f27f9b748ea603ec700">563a802</a>)</li>
<li><strong>Schema:</strong> update to v51.2.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/e301dcc22d5592658673f55d397ac7bc7e0fe409">e301dcc</a>)</li>
<li><strong>Schema:</strong> update to v52.0.1 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/2cf955525a805dba74218bb6af4ac0e0954852c9">2cf9555</a>)</li>
<li><strong>Schema:</strong> update to v52.1.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/a8c33533666b36475ee02d3629e639d028e0f6a0">a8c3353</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/compare/v10.13.27...v10.13.28">10.13.28</a> (2025-03-06)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/f7c528f69c922ad080e6132a17dbfcda9b7b83f7">f7c528f</a>)</li>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/527e2bc90a6b7f7699f9c969251fe4ba57a67b0b">527e2bc</a>)</li>
<li><strong>Schema:</strong> update to v51.0.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/2910431df38c978dffcbbd246f2f13a3232293d3">2910431</a>)</li>
<li><strong>Schema:</strong> update to v51.1.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/8719efb201d465791f6783c94d71294759933832">8719efb</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/compare/v10.13.26...v10.13.27">10.13.27</a> (2025-03-04)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> update to v50.4.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/7f406e9c5dff508943270c1e960ff8c48052e76f">7f406e9</a>)</li>
<li><strong>Schema:</strong> update to v50.4.1 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/faa1e6df6746824ee6ef6d7bf9514019abd2273d">faa1e6d</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/compare/v10.13.25...v10.13.26">10.13.26</a> (2025-02-28)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> update to v50.0.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/3efcefb2bb59ef2367b12f1799d6d8d687dba2ea">3efcefb</a>)</li>
<li><strong>Schema:</strong> update to v50.1.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/02a38722c8175432723d9789cd742f88913748c5">02a3872</a>)</li>
<li><strong>Schema:</strong> update to v50.2.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/bbad12c83b0d3071ac8ed92563d2891ce1d614fb">bbad12c</a>)</li>
<li><strong>Schema:</strong> update to v50.3.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/7ba6a145465fc397de3f53b30575aa70b29564a1">7ba6a14</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/compare/v10.13.24...v10.13.25">10.13.25</a> (2025-02-24)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> update to v49.8.1 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/bc999f6e7e53a2eeb853472ab867e92f7e6fc628">bc999f6</a>)</li>
<li><strong>Schema:</strong> update to v49.9.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/201121ecdfb18e6b4eaf6f7cfd2ffd98ced1bf76">201121e</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/compare/v10.13.23...v10.13.24">10.13.24</a> (2025-02-20)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/3f555f2ccad01a52ec5a66cec35d44a34b76b058">3f555f2</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/compare/v10.13.22...v10.13.23">10.13.23</a> (2025-02-20)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/6bc2afc192cd0b89148ce5e02d494237b9964c27">6bc2afc</a>)</li>
<li><strong>Schema:</strong> update to v49.8.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/a8bee7ec6993085489774334aa45059acf56367b">a8bee7e</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/compare/v10.13.21...v10.13.22">10.13.22</a> (2025-02-19)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Cache:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/ba8f1a59b1ac42320cf87963ae8ecff94d5bca8d">ba8f1a5</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/compare/v10.13.20...v10.13.21">10.13.21</a> (2025-02-18)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/03e63c4f9875fa40bd3d197e9f3bd9eaec4e1ad9">03e63c4</a>)</li>
<li><strong>Report views:</strong> add ctx to share link buttons (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/8f25dcebb1e2fe8a44318c6cdaf72106a54a04b0">8f25dce</a>)</li>
<li><strong>Schema:</strong> update to v49.7.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/09e096f555bad7c09762ce4d60c5507eef963565">09e096f</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/compare/v10.13.19...v10.13.20">10.13.20</a> (2025-02-17)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> update to v49.5.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/9b7c5e780d1dbd35d09a4d0b738a5eb31081ab8a">9b7c5e7</a>)</li>
<li><strong>Schema:</strong> update to v49.6.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/fbd45b9e597cc74b5d38491dff06cd63e463e870">fbd45b9</a>)</li>
<li><strong>Schema:</strong> update to v49.6.1 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/985a1c289961b6fbf4527d1fc1cf49a6ba978535">985a1c2</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/compare/v10.13.18...v10.13.19">10.13.19</a> (2025-02-14)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/537ee3b6f7b58496e8270eec65439c0a65e73ae5">537ee3b</a>)</li>
<li><strong>Schema:</strong> update to v49.4.1 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/dd1e132773fb2983675f9aa2eb4af68c69f0059c">dd1e132</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/compare/v10.13.17...v10.13.18">10.13.18</a> (2025-02-11)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> update to v49.0.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/14441f518f3b6b65b5967bc43f99d83259b73329">14441f5</a>)</li>
<li><strong>Schema:</strong> update to v49.1.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/241114574fd5cf054d0f89ff12a533cb26b316b3">2411145</a>)</li>
<li><strong>Schema:</strong> update to v49.2.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/f58d567fb2b9e828017a2d78742d4a07b50b46c4">f58d567</a>)</li>
<li><strong>Schema:</strong> update to v49.2.1 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/70c279170d15cadcc38025262af02299a8885e97">70c2791</a>)</li>
<li><strong>Schema:</strong> update to v49.3.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/643b2977449441f8be97fad9cc438a922454c843">643b297</a>)</li>
<li><strong>Schema:</strong> update to v49.3.1 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/9c738b818e9ad3e67abd88ec543d83595d18ee55">9c738b8</a>)</li>
<li><strong>Schema:</strong> update to v49.4.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/73c5f09f9a74f4541d586804439ef0356bf57de1">73c5f09</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/compare/v10.13.16...v10.13.17">10.13.17</a> (2025-02-03)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> update to v48.12.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/7749b3b3d02e7f35a3496a1bd68b3d836e055324">7749b3b</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/compare/v10.13.15...v10.13.16">10.13.16</a> (2025-02-03)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/935847106035203969e96bb5c78eec843dacd306">9358471</a>)</li>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/6dfc31982e8ee703c8686d18c1a11d798c6210ae">6dfc319</a>)</li>
<li><strong>Schema:</strong> update to v48.10.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/4fcdf7684f3243a71d835424d9c28e2e95622af5">4fcdf76</a>)</li>
<li><strong>Schema:</strong> update to v48.10.1 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/d5d88e5c00944e96df03b6a43497aec839d4ee7a">d5d88e5</a>)</li>
<li><strong>Schema:</strong> update to v48.9.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/8afe48ac2c6271a6be23ab43adaff9733181c7ec">8afe48a</a>)</li>
<li><strong>Schema:</strong> update to v48.9.1 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/a0d9237694b92062876d94ba92cef7ee840eb461">a0d9237</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/compare/v10.13.14...v10.13.15">10.13.15</a> (2025-01-24)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> update to v48.8.2 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/a03a7e36e7c728b247b75503870004aae5cc6f31">a03a7e3</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/compare/v10.13.13...v10.13.14">10.13.14</a> (2025-01-22)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> update to v48.7.1 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/9943c872e9928c15c08f06ffd8445f9ad1295664">9943c87</a>)</li>
<li><strong>Schema:</strong> update to v48.8.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/39541ea57d81979d0839c118c8176889c087c6c1">39541ea</a>)</li>
<li><strong>Schema:</strong> update to v48.8.1 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/fecaec27552b90e57e418fd702c2c490b4dbe338">fecaec2</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/compare/v10.13.12...v10.13.13">10.13.13</a> (2025-01-22)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> update to v48.7.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/9755632890faeefafd48416cf6c4e34a8c367f51">9755632</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/compare/v10.13.11...v10.13.12">10.13.12</a> (2025-01-20)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> update to v48.6.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/55ed497221dbc92bc9bdf3baa974cd81c4bc8438">55ed497</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/compare/v10.13.10...v10.13.11">10.13.11</a> (2025-01-15)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/33426507ff8daaf37e445a488bff79f0c291242f">3342650</a>)</li>
<li><strong>Schema:</strong> update to v48.3.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/9a216963cac23ef79be9e90f0becc19a4818a945">9a21696</a>)</li>
<li><strong>Schema:</strong> update to v48.4.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/34445144038724869712f96ba55e07712ef1a9f1">3444514</a>)</li>
<li><strong>Schema:</strong> update to v48.4.1 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/4bfda29b679f15d3e7c5e3df46f42da16f818c50">4bfda29</a>)</li>
<li><strong>Schema:</strong> update to v48.5.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/51d04e964bb6ebcfedcd74d1603b56fc4df640f8">51d04e9</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/compare/v10.13.9...v10.13.10">10.13.10</a> (2025-01-07)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Archive Location:</strong> fix wording (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/50332853cedca953321ab87765d6b51a1b1af0d8">5033285</a>)</li>
<li><strong>Schema:</strong> update to v48.1.4 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/0183baf70d56000f161f5367ec851b2a22c0bc41">0183baf</a>)</li>
<li><strong>Schema:</strong> update to v48.1.5 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/5754f36934a258b7f60c5ee513e4c08aa6a3f309">5754f36</a>)</li>
<li><strong>Schema:</strong> update to v48.2.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/fb2783829f59e8310fcfb899acd562e405a36d0b">fb27838</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/compare/v10.13.8...v10.13.9">10.13.9</a> (2025-01-02)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/4a6e428435f1beaae42fc86f3ba9227621eaa64b">4a6e428</a>)</li>
<li><strong>Risk Search:</strong> fix navigation to open risks and open supportability risks (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/d286a7368f916bc32a94f45e7512c4fe6bf23b89">d286a73</a>)</li>
<li><strong>Schema:</strong> update to v48.1.3 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/487f4ec1b5f4acabbb79bb6e86cf17f20437906f">487f4ec</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/compare/v10.13.7...v10.13.8">10.13.8</a> (2024-12-19)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> update to v48.1.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/031b142d760d26b165203b07331d1a5ed05ba3f0">031b142</a>)</li>
<li><strong>Schema:</strong> update to v48.1.1 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/1cd761993b537836d36dfdd2f9eb9e97ae53465f">1cd7619</a>)</li>
<li><strong>Schema:</strong> update to v48.1.2 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/42a4a0cd92ed2e5ff23ed29b8994298b0c6937bf">42a4a0c</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/compare/v10.13.6...v10.13.7">10.13.7</a> (2024-12-17)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/7263d054162b3c24483ce3960ccbe04d0b60313a">7263d05</a>)</li>
<li><strong>Schema:</strong> update to v48.0.2 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/8f3d425781a64159efccff01346a8c42bdb309f4">8f3d425</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/compare/v10.13.5...v10.13.6">10.13.6</a> (2024-12-13)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> update to v46.0.0-at-waiver-date-hotfix (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/8cc29deb021a1807f31ccf25706eb1ed8a323d42">8cc29de</a>)</li>
<li><strong>Schema:</strong> update to v47.0.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/c17016ae629edef14284ed16cbeb75848d3d6683">c17016a</a>)</li>
<li><strong>Schema:</strong> update to v47.0.1 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/43e4cdd7ab09ec145f9951d57c8cafe792f6ea6c">43e4cdd</a>)</li>
<li><strong>Schema:</strong> update to v47.0.2 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/68fb47507f88077a79ef7d5c9b70aa4d65d82c83">68fb475</a>)</li>
<li><strong>Schema:</strong> update to v47.1.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/83fd6b9279fbe8a140ce2eac18432c8441fa5f1e">83fd6b9</a>)</li>
<li><strong>Schema:</strong> update to v47.1.1 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/725e2c1be1f4d9a03e9a39e2c6340bd93d8995b1">725e2c1</a>)</li>
<li><strong>Schema:</strong> update to v48.0.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/792e6bf808b2f73e04f6449981aed3a089e47105">792e6bf</a>)</li>
<li><strong>Schema:</strong> update to v48.0.1 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/a4a640bbae30c3323a8d7bfa7babafd65f7773ac">a4a640b</a>)</li>
<li><strong>TopRisksExport:</strong> fix pdf export (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/d904593da07d87f21e476a295eda3dea95117b2f">d904593</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/compare/v10.13.4...v10.13.5">10.13.5</a> (2024-12-03)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> update to v46.0.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/d42e1df271daff191d9bb0a8213255db2ef4bc18">d42e1df</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/compare/v10.13.3...v10.13.4">10.13.4</a> (2024-11-23)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> update to v45.1.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/a480947fd68cd0b6291397e70ed3f219f4bc6bc6">a480947</a>)</li>
<li><strong>Schema:</strong> update to v45.1.1 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/ab3e998467d3fe2c07a6456e39490376e82ffe7f">ab3e998</a>)</li>
<li><strong>Schema:</strong> update to v45.2.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/9520f223c6cd5df39e8ea36b98c1f9a6da1a962c">9520f22</a>)</li>
<li><strong>Schema:</strong> update to v45.3.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/74276f50132939021ff8ccbf6c9257486d7b9dcd">74276f5</a>)</li>
<li><strong>Schema:</strong> update to v45.3.1 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/d3b865ed5c21411db329aebc2c19552ff3148f34">d3b865e</a>)</li>
<li><strong>Schema:</strong> update to v45.3.2 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/9000bc86529dcafc170e09c6326163942dc662e9">9000bc8</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/compare/v10.13.2...v10.13.3">10.13.3</a> (2024-11-18)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/94044462c75cae88efb3ce5a3a0f7e247226a976">9404446</a>)</li>
<li><strong>Orb Notes:</strong> fix visible HTML (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/7be76f07166b9a8383aa7d2063703448f62ae956">7be76f0</a>)</li>
<li><strong>Schema:</strong> update to v45.0.3 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/33c713dd47849ee859da669c198df92123db9797">33c713d</a>)</li>
<li><strong>Schema:</strong> update to v45.0.4 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/8d0d98d15e18c785e82012804ec3f8f14f64e996">8d0d98d</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/compare/v10.13.1...v10.13.2">10.13.2</a> (2024-11-15)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> update to v45.0.2 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/4d7581310988e7b417be9ba0b885f81d437f276b">4d75813</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/compare/v10.13.0...v10.13.1">10.13.1</a> (2024-11-13)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/c6d9731d0d8fe29a022a1961202c4ec1b0bcd4ad">c6d9731</a>)</li>
<li><strong>Schema:</strong> update to v45.0.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/f1cca27f93b6dba319fcf2334e7fdef7d1ab4d82">f1cca27</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/compare/v10.12.12...v10.13.0">10.13.0</a> (2024-11-12)</h1>
<h3>Features</h3>
<ul>
<li><strong>Schema:</strong> update to v44.1.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/9f744a2f2b623e457572191c09960f49036cc9cd">9f744a2</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/compare/v10.12.11...v10.12.12">10.12.12</a> (2024-11-08)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Auth:</strong> is hard. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/cc6869d8329b50e4b9f55282a0819d11f832071c">cc6869d</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/compare/v10.12.10...v10.12.11">10.12.11</a> (2024-11-08)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Auth:</strong> is hard. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/ee43f43647aeafef1143cac76710f4b29b3655d4">ee43f43</a>)</li>
<li><strong>Edit Risk:</strong> updated edit risk modal (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/15051252e062b052c2ae6c2141ed642e4af7ed75">1505125</a>)</li>
<li><strong>Links:</strong> updated links modal (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/cc2b117dd7a6e58ce00659d9d3607f5b91fe6445">cc2b117</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/compare/v10.12.9...v10.12.10">10.12.10</a> (2024-11-06)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/26f6f2b97a1a43044f7d24a34800726eb3566cc7">26f6f2b</a>)</li>
<li><strong>Schema:</strong> update to v43.3.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/01260fa94f4e83fa77758c3ce94f9f413b6d24ee">01260fa</a>)</li>
<li><strong>Schema:</strong> update to v43.3.1 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/f21b14f8796768a1c54ba8a8c5b56d9ab72618c3">f21b14f</a>)</li>
<li><strong>Schema:</strong> update to v43.3.2 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/6de585a4b91cbb7d603de5a4550286579340eb42">6de585a</a>)</li>
<li><strong>Schema:</strong> update to v43.3.3 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/23b4bfe695c79b2e00725a3bd312bac3b83cbda8">23b4bfe</a>)</li>
<li><strong>Schema:</strong> update to v43.3.4 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/1c756733d07f2710d78970b1c48f43dcb7e955eb">1c75673</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/compare/v10.12.8...v10.12.9">10.12.9</a> (2024-11-01)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> update to v43.2.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/0be4dec9f46b9ceea85219280ae810d5dd379b6a">0be4dec</a>)</li>
<li><strong>Schema:</strong> update to v43.2.1 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/32f6c13637f319e524e084d53f2e2ca6832c16e8">32f6c13</a>)</li>
<li><strong>Schema:</strong> update to v43.2.2 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/fae388322704f48676f1b6677d3a85f16fc4b498">fae3883</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/compare/v10.12.7...v10.12.8">10.12.8</a> (2024-10-30)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Http dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/f61f643544d9870cbedb805c23526177f3c51df5">f61f643</a>)</li>
<li><strong>Schema:</strong> update to v43.1.1 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/8d9a350ac212207ba847b3242c4eacb23c478b39">8d9a350</a>)</li>
<li><strong>Schema:</strong> update to v43.1.2 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/9fb803ac77b8f7be0cec9f4bbb4643d516d22789">9fb803a</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/compare/v10.12.6...v10.12.7">10.12.7</a> (2024-10-23)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> update to v43.1.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/b86a3a6d60e885e407d63e538a5da5c938323b95">b86a3a6</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/compare/v10.12.5...v10.12.6">10.12.6</a> (2024-10-22)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/7e624391b9ba28ca53cd77f9cfde81ba859e1feb">7e62439</a>)</li>
<li><strong>Schema:</strong> update to v43.0.2 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/24566d7ae50674bd5e0a307cd3fbfc58353cd721">24566d7</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/compare/v10.12.4...v10.12.5">10.12.5</a> (2024-10-09)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Comments:</strong> updated to use API v2 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/ad3b763cc54f7e131893e92c5fec1be1fb094259">ad3b763</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/compare/v10.12.3...v10.12.4">10.12.4</a> (2024-10-09)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/f1fa40908765867d3e39a5125cead254a7b6707b">f1fa409</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/compare/v10.12.2...v10.12.3">10.12.3</a> (2024-10-08)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/3e42a55df4c59443f3a5cdc7c1ae88cb5926b7bd">3e42a55</a>)</li>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/ec3597be2a6bfc3b1ddbded4cf5eb25602f93eab">ec3597b</a>)</li>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/4ea24dee8fbb643a4cade14169477f4f985b2f7c">4ea24de</a>)</li>
<li><strong>Schema:</strong> update to v41.6.1 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/33909ada138d69809379a362c692d3d9b1e2483c">33909ad</a>)</li>
<li><strong>Schema:</strong> update to v41.6.2 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/453444aff074783e199579f25eb04d9e66641450">453444a</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/compare/v10.12.1...v10.12.2">10.12.2</a> (2024-10-03)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/02c53b79eacda5a3ff6db5eaf37ed7dd14fda0f8">02c53b7</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/compare/v10.12.0...v10.12.1">10.12.1</a> (2024-10-03)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/02bae521333a991616538e471bc0a408e3d1dad6">02bae52</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/compare/v10.11.0...v10.12.0">10.12.0</a> (2024-10-01)</h1>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> update to v41.5.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/ea8856b80806f82d53a1621a7d1972a2f28077ff">ea8856b</a>)</li>
</ul>
<h3>Features</h3>
<ul>
<li><strong>Schema:</strong> update to v41.6.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/8a53c951a1b91863f6dac1a0c1abaf4854cf5ff6">8a53c95</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/compare/v10.10.0...v10.11.0">10.11.0</a> (2024-09-25)</h1>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/f48bd7e780c74bf518ebe2e655104d0d4803d0ae">f48bd7e</a>)</li>
<li><strong>Schema:</strong> update to v41.4.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/1ddd0182b1f1f1afe0ee048f87e52c71ec963f27">1ddd018</a>)</li>
<li><strong>Schema:</strong> update to v41.4.1 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/792eb33016ac2b8b8abc5365025fb8cf7e2efeb4">792eb33</a>)</li>
</ul>
<h3>Features</h3>
<ul>
<li><strong>Excel export:</strong> update improves the export with styling and proper ordering of mitigation steps (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/c0bcdacddab0949253aba70df766d045e1eddb6e">c0bcdac</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/compare/v10.9.3...v10.10.0">10.10.0</a> (2024-09-24)</h1>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/1fa5c87b941f870b435b02692e046e73e26af771">1fa5c87</a>)</li>
<li><strong>Schema:</strong> update to v41.3.1 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/c778f9012366cfe9bd9c418083e5d426db1ae0a6">c778f90</a>)</li>
<li><strong>Schema:</strong> update to v41.3.2 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/b894625263f3a5585d3928dbd66ab293b06419ef">b894625</a>)</li>
</ul>
<h3>Features</h3>
<ul>
<li><strong>Docker:</strong> update root node location (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/4185f6bb27750aa1b5239e9d2a72a475f54e9b1c">4185f6b</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/compare/v10.9.2...v10.9.3">10.9.3</a> (2024-09-23)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/54b8b415f6951930da67eb366bb1b5e28b86617a">54b8b41</a>)</li>
<li><strong>Schema:</strong> update to v41.1.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/7ce762bd2d608f25b14ca193ed65d806fb78096c">7ce762b</a>)</li>
<li><strong>Schema:</strong> update to v41.2.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/25bc102ebd87302f3216926d2eb795b8d1b95254">25bc102</a>)</li>
<li><strong>Schema:</strong> update to v41.2.1 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/a0d10e63dff0a49979d71aea7a4542855b9c303c">a0d10e6</a>)</li>
<li><strong>Schema:</strong> update to v41.2.2 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/7d49ad93d3846ae4c5c7cb0e5b6f419d6909ccd6">7d49ad9</a>)</li>
<li><strong>Schema:</strong> update to v41.3.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/3b491d4b3ce9f665ec245a9a48fe3e412dcda1da">3b491d4</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/compare/v10.9.1...v10.9.2">10.9.2</a> (2024-09-19)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Bringing new context online. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/57f1dbccacf9db12360a789af82c8b6ebf084d7b">57f1dbc</a>)</li>
<li><strong>Risk manage:</strong> update title spacing for longer risk titles (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/4f2997cd5c45c1f271b85e08055d492a5624b1ae">4f2997c</a>)</li>
<li><strong>UI:</strong> improved date picker popover (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/25eabb7dd994a3e9f025ad234e53f533105cd51b">25eabb7</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/compare/v10.9.0...v10.9.1">10.9.1</a> (2024-09-18)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/938a1dd54fcf69ce2791c92b15aa6fc5683c240a">938a1dd</a>)</li>
<li><strong>Schema:</strong> update to v41.0.2 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/ba29cddd299b1caf6a3b9abfa63dd31e15946b95">ba29cdd</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/compare/v10.8.0...v10.9.0">10.9.0</a> (2024-09-16)</h1>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/a1942edf9d37f812406821d14c489a9156c55799">a1942ed</a>)</li>
</ul>
<h3>Features</h3>
<ul>
<li><strong>Email:</strong> adds the ability to email users about a Risk in question (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/119c28afd179851bc93e4c092135d15e15a66087">119c28a</a>)</li>
<li><strong>Schema:</strong> update to v41.0.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/ac544f9da59da51cae8028696dd7941b00f2c786">ac544f9</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/compare/v10.7.2...v10.8.0">10.8.0</a> (2024-09-13)</h1>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Archive Risk:</strong> updated color and buttons (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/67985949a073f05daa1842aea76e776ef78c7fde">6798594</a>)</li>
<li><strong>Risk export:</strong> adding a link back to COSMIC Risk in the excel export (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/de32a3b1b947ba21da719381a81cd145e1eb24bd">de32a3b</a>)</li>
</ul>
<h3>Features</h3>
<ul>
<li><strong>Risk manage:</strong> updated layout (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/71feb098a90d06bbeaa24d60e266ff94039883e4">71feb09</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/compare/v10.7.1...v10.7.2">10.7.2</a> (2024-09-13)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Home:</strong> export button disables when in process of exporting (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/137db2e3aa1a8181eb827cb67b4d5fa182deb11d">137db2e</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/compare/v10.7.0...v10.7.1">10.7.1</a> (2024-09-13)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Home:</strong> correct unforseen error (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/302efa35b72c6ba76746e712aa0c448a60e83720">302efa3</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/compare/v10.6.2...v10.7.0">10.7.0</a> (2024-09-13)</h1>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/e1b1d9a417dc30941c368e11dbb12cbec3e9666a">e1b1d9a</a>)</li>
</ul>
<h3>Features</h3>
<ul>
<li><strong>Excel:</strong> export to excel (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/ccb85e12b1b9ad14184f7fe9dae5b244c12c30d1">ccb85e1</a>)</li>
<li><strong>Schema:</strong> update to v40.0.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/475cd6ab19b1108bd13baab6c06d422ed57570b1">475cd6a</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/compare/v10.6.1...v10.6.2">10.6.2</a> (2024-09-12)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/49f5b3b345762da44928c947cda3dca04f5221e0">49f5b3b</a>)</li>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/c95e1cb77d7694438d672462167e52b796ea8c38">c95e1cb</a>)</li>
<li><strong>Schema:</strong> update to v39.0.1 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/160e6d184b20312736cb25348ce5b5fa1735566d">160e6d1</a>)</li>
<li><strong>Schema:</strong> update to v39.0.2 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/4e274e65e06f7e24e7edfa80f2991ed0088cd2a7">4e274e6</a>)</li>
<li><strong>Schema:</strong> update to v39.1.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/94ac981dec97860ebf4c542ea2d821bb1ff9c9be">94ac981</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/compare/v10.6.0...v10.6.1">10.6.1</a> (2024-09-05)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/f7e255fdf70b53946e96a40d103608bae9112cfe">f7e255f</a>)</li>
<li><strong>Risk Review:</strong> updated risk review control (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/3fc7a55f664941025c9f1bcee885892d88c470e8">3fc7a55</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/compare/v10.5.1...v10.6.0">10.6.0</a> (2024-08-30)</h1>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/b6eee34b0c1ce0e56881a05e94a60649bc1dc471">b6eee34</a>)</li>
<li><strong>Schema:</strong> update fixes breaks from v39.0.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/6190e10205a244f5bd27ad44c3663a56da152ef5">6190e10</a>)</li>
<li><strong>UI:</strong> streamlined radio button controls (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/bbe773bb684642399a692b61938a12eab110264a">bbe773b</a>)</li>
</ul>
<h3>Features</h3>
<ul>
<li><strong>Schema:</strong> update to v39.0.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/ab5cb15c4b2526a664a3fee50821594ea9001b33">ab5cb15</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/compare/v10.5.0...v10.5.1">10.5.1</a> (2024-08-27)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/d30ff45c03f814fefe3912890e17da1b1f0221f0">d30ff45</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/compare/v10.4.1...v10.5.0">10.5.0</a> (2024-08-21)</h1>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Home:</strong> update allows the user to filter quickly by risk scores &gt;= 10 or &lt;= 10 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/ba44ba30e4868438f87036d02471a9602adeb4b9">ba44ba3</a>)</li>
</ul>
<h3>Features</h3>
<ul>
<li><strong>Modal:</strong> implementing new webcomponent backed modal (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/642b58e531dbaf835ad7fb06f865890aeb41d216">642b58e</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/compare/v10.4.0...v10.4.1">10.4.1</a> (2024-08-18)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/e8ec3b56989f4c699bd1456c32fef09fd8829e1d">e8ec3b5</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/compare/v10.3.1...v10.4.0">10.4.0</a> (2024-08-17)</h1>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/58feefff61fd554f1a39bad1b4ebaaaa7758f8ec">58feeff</a>)</li>
<li><strong>Home:</strong> added improvements for our lead dev (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/80e46227788583bde99eeb063ace1dea64a65c16">80e4622</a>)</li>
<li><strong>Home:</strong> corrected sort issue making the app objectively better today than it was yesterday. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/20af6c7bea171b7450067a1d3779beba17094769">20af6c7</a>)</li>
</ul>
<h3>Features</h3>
<ul>
<li><strong>Risk reviewing:</strong> ability to start reviewing risks from the home view (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/da98d93cd8a4bee569459c39b2f8daabb049fa1d">da98d93</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/compare/v10.3.0...v10.3.1">10.3.1</a> (2024-08-16)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>HomeView:</strong> view open risks (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/aac65edf70089e3b4328d0ce7a827d23f4f050d7">aac65ed</a>)</li>
<li><strong>Risk mitigation create:</strong> add exit criteria to creation (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/ad9719f80cbd3f86922105b3dade8a0e8499cd21">ad9719f</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/compare/v10.2.0...v10.3.0">10.3.0</a> (2024-08-15)</h1>
<h3>Bug Fixes</h3>
<ul>
<li><strong>RiskMitigationStep:</strong> disable delete button on closed mitigation steps (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/e7f72006455dbf3a56b8224a74989117c36f5fc3">e7f7200</a>)</li>
<li><strong>Schema:</strong> update to v38.4.3 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/bddb06ea478e3e532047d9cf79b21ebbeafec582">bddb06e</a>)</li>
<li><strong>Schema:</strong> update to v38.5.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/62c4b165e0fd38ca1272e5bba465a00b0507ae5e">62c4b16</a>)</li>
</ul>
<h3>Features</h3>
<ul>
<li><strong>Risk Mitigation:</strong> ability to add the &quot;impact&quot; of a risk mitigation steps (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/5ef2901bd1a1b91379c0aabc7cc6dcdf1d028842">5ef2901</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/compare/v10.1.1...v10.2.0">10.2.0</a> (2024-08-13)</h1>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> update to v38.2.2 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/ade8ffbc2103a289b551e26d1f9d29dc1c4d7a0a">ade8ffb</a>)</li>
<li><strong>Schema:</strong> update to v38.4.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/b755a491519de9067552d3038c15ac0f561af596">b755a49</a>)</li>
<li><strong>Schema:</strong> update to v38.4.1 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/af9ac83d0a2dfefff79db118802a43d025f5f555">af9ac83</a>)</li>
<li><strong>Schema:</strong> update to v38.4.2 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/55649500d5f4d90477073e5fefbf99bafbec9a42">5564950</a>)</li>
<li><strong>Standardization:</strong> implement icons in top nav as well as other wonderful augmentations. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/d704c03dea2a27642a5ba63f2ae8cf628e535101">d704c03</a>)</li>
</ul>
<h3>Features</h3>
<ul>
<li><strong>Admins view:</strong> update adds more application level details to a new admin tab (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/fb623260c68235c590533257fdfde153bb7427ab">fb62326</a>)</li>
<li><strong>UI:</strong> implemented new button web component (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/6a5768dbda220970d045d88f849bb8d5fd4057e7">6a5768d</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/compare/v10.1.0...v10.1.1">10.1.1</a> (2024-07-31)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/1ed8a239e98ef0fd1cd27097680a60be2bfaab43">1ed8a23</a>)</li>
<li><strong>Schema:</strong> update to v38.0.1 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/741f982ba6432c731c1bd3683444d8068a761852">741f982</a>)</li>
<li><strong>Schema:</strong> update to v38.1.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/42c321345631a53829af01c9303307fc16e4ebc9">42c3213</a>)</li>
<li><strong>Schema:</strong> update to v38.2.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/f6ee11cd82a16b51372fa9cc9d1ccd1b492be19d">f6ee11c</a>)</li>
<li><strong>Schema:</strong> update to v38.2.1 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/ad7da7b64ce87b549998fb23485f9ae0fb90bf7c">ad7da7b</a>)</li>
<li><strong>Standardization:</strong> implement icons in top nav as well as other wonderful augmentations. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/1fa8e2caade58beaca46f1489dbc524fc0c8e1c8">1fa8e2c</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/compare/v10.0.0...v10.1.0">10.1.0</a> (2024-07-25)</h1>
<h3>Bug Fixes</h3>
<ul>
<li><strong>UI:</strong> restored more familiar hardware icons and updated the edit icon (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/179096e059dff18f0e1712308598ceb6369a8f2c">179096e</a>)</li>
</ul>
<h3>Features</h3>
<ul>
<li><strong>Authorization:</strong> overhauled the order of operations for authorization and authentication (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/0e197b046ce77619657d4bc8cb4d33dbb8691f76">0e197b0</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/compare/v9.0.1...v10.0.0">10.0.0</a> (2024-07-22)</h1>
<h3>Features</h3>
<ul>
<li><strong>Schema:</strong> update to v38.0.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/1e61dbaa2f2fdfd746b899d4274b2059502b18bc">1e61dba</a>)</li>
</ul>
<h3>BREAKING CHANGES</h3>
<ul>
<li><strong>Schema:</strong> date/time updates system wide</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/compare/v9.0.0...v9.0.1">9.0.1</a> (2024-07-18)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Build:</strong> correct build error preventing deployment (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/f7d9f0854ad2fa8f0bfa5468a1b447e3f3118f7e">f7d9f08</a>)</li>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/346087cce9b132b6473db63f4b3f9f5c19e3003d">346087c</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/compare/v8.0.3...v9.0.0">9.0.0</a> (2024-07-11)</h1>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Asset pathing:</strong> update to point back to the proxy (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/66c9099251f1f65d2d4681134fc8aee09114c304">66c9099</a>)</li>
<li><strong>COSMIC:</strong> Dependency update for deployment. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/6af52603ac94d07972e337bc9448eaf2d468f925">6af5260</a>)</li>
<li><strong>Schema:</strong> update to v36.0.2 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/1389f6071474cf9175697ad02867df1cf01df9cd">1389f60</a>)</li>
<li><strong>Schema:</strong> update to v37.0.1 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/d33b30d25c6563051b777a9b40b82ebd307e434e">d33b30d</a>)</li>
</ul>
<h3>Features</h3>
<ul>
<li><strong>Schema:</strong> update to v36.0.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/42b0a3820f62212ff698078e80ab8c697559378e">42b0a38</a>)</li>
<li><strong>Tables:</strong> change to table styles (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/7a2e4539effc3b13826e9425c98937f1c14f13e6">7a2e453</a>)</li>
</ul>
<h3>BREAKING CHANGES</h3>
<ul>
<li><strong>Schema:</strong> column renames and date/time related updates</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/compare/v8.0.2...v8.0.3">8.0.3</a> (2024-06-25)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Asset path:</strong> Update GCP bucket url. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/4b21b97a2bd9f377359857810bd4e71b947babca">4b21b97</a>)</li>
<li><strong>Footer:</strong> updated knowledgebase link (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/8a863ff3c13d58ca437ac2384f7697132cebee55">8a863ff</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/compare/v8.0.1...v8.0.2">8.0.2</a> (2024-06-19)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Home:</strong> defaulting to open risks (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/9de1c55e4fe5c3bda13e8aa7bc537c5d54431564">9de1c55</a>)</li>
<li><strong>outages:</strong> updated outages height (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/39813f672c5ddd4b16ec316c50252bac1981511d">39813f6</a>)</li>
<li><strong>Schema:</strong> update to v35.1.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/c002610e7aea03d10c498e4bbfeeb0e544e5b3f9">c002610</a>)</li>
<li><strong>Schema:</strong> update to v35.1.1 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/48ddef5eacb8b160b56f330e44185c3d7343dd42">48ddef5</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/compare/v8.0.0...v8.0.1">8.0.1</a> (2024-06-18)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Archiving:</strong> updated risk archive (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/522df47e251d3c2dee44c9e7335ffe5d1777dddd">522df47</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/compare/v7.1.4...v8.0.0">8.0.0</a> (2024-06-18)</h1>
<h3>Features</h3>
<ul>
<li><strong>Schema:</strong> update to v35.0.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/f039243fe4ea4d53986774f0d92eb2f5591a5ff3">f039243</a>)</li>
</ul>
<h3>BREAKING CHANGES</h3>
<ul>
<li><strong>Schema:</strong> createdDate/updatedDate rename</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/compare/v7.1.3...v7.1.4">7.1.4</a> (2024-06-18)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> update to v34.1.1 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/53fcf4ea47c66663f60da93b281943d722fadf8f">53fcf4e</a>)</li>
<li><strong>Schema:</strong> update to v34.1.2 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/060a18054900e2fe5f57ab91a16d741d84b4fc86">060a180</a>)</li>
<li><strong>Schema:</strong> update to v34.1.3 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/ae0283183ce24ee0bb7f5cfeb0315068204cfe5b">ae02831</a>)</li>
<li><strong>Static assets:</strong> update pathing per environment (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/4f711e7615bac8d12405a1f2180de627e251e7d9">4f711e7</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/compare/v7.1.2...v7.1.3">7.1.3</a> (2024-06-17)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/fae2d12c3b1fa5849c7a91f76218d049ac014d19">fae2d12</a>)</li>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/49c151001f796af207158af9a9ffc332e9a42344">49c1510</a>)</li>
<li><strong>Schema:</strong> update to v34.1.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/9d4ab792f8b442d91fffe889a3c7e5827354b3b0">9d4ab79</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/compare/v7.1.1...v7.1.2">7.1.2</a> (2024-06-14)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> update to v34.0.2 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/87a5def941267d7e328d891ec006c5057784d1f4">87a5def</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/compare/v7.1.0...v7.1.1">7.1.1</a> (2024-06-12)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> update to v34.0.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/59d318fe689b51930e498634b91e0d4b25490d86">59d318f</a>)</li>
<li><strong>Schema:</strong> update to v34.0.1 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/019e84b0c1d307b0cc656ab52069f40cf0ae4777">019e84b</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/compare/v7.0.1...v7.1.0">7.1.0</a> (2024-06-11)</h1>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> update to v32.1.1 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/8e0d0aa57af231bd317f9a847df7bbdd460b7b18">8e0d0aa</a>)</li>
<li><strong>Schema:</strong> update to v33.0.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/3822143206ecb9adc2fedd61684526a624a5abfb">3822143</a>)</li>
</ul>
<h3>Features</h3>
<ul>
<li><strong>Dates:</strong> attempt at fixing datetimes (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/06c8dd574374c76e500cf91ed13382ad8a15ffa9">06c8dd5</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/compare/v7.0.0...v7.0.1">7.0.1</a> (2024-06-10)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Misc:</strong> update image pathing (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/245ae07a2e1af4cd94098fdb197a988697682cdc">245ae07</a>)</li>
<li><strong>Risk Search:</strong> new risk search with more filters (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/14df0331ad715da6c58d9316651f69303825cc20">14df033</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/compare/v6.0.8...v7.0.0">7.0.0</a> (2024-06-10)</h1>
<h3>Features</h3>
<ul>
<li><strong>Static resource mgmt:</strong> change implements current best practices for serving static content (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/f360ae654cd4e8e85a70fc9386ffae5daf1844c2">f360ae6</a>)</li>
</ul>
<h3>BREAKING CHANGES</h3>
<ul>
<li><strong>Static resource mgmt:</strong> images could need to be remapped</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/compare/v6.0.7...v6.0.8">6.0.8</a> (2024-06-07)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/066a2f659889191824ff25b0568a61de20297f27">066a2f6</a>)</li>
<li><strong>RiskManage:</strong> Updated to always show status tile. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/205417abeddba4b93660866006ea26b1c4b2243d">205417a</a>)</li>
<li><strong>Schema:</strong> update to v31.2.1 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/8451455c1b05b1d56b70ff5770754845c8e6b758">8451455</a>)</li>
<li><strong>Schema:</strong> update to v32.0.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/8215336574bc21cc9f72ba71e2f0e709c5887b88">8215336</a>)</li>
<li><strong>Schema:</strong> update to v32.1.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/68638b961aeab10e34f6c6333afc70f9fcb25cfd">68638b9</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/compare/v6.0.6...v6.0.7">6.0.7</a> (2024-06-04)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> update to v31.2.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/a9d028c3e4abe69c4ebe2b9004dbdb18e109252a">a9d028c</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/compare/v6.0.5...v6.0.6">6.0.6</a> (2024-06-03)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> update to v31.1.1 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/62396834eca2b31950e515c682b169b04244bd3c">6239683</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/compare/v6.0.4...v6.0.5">6.0.5</a> (2024-05-31)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Change log:</strong> prevent empty logs, update edit form validation (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/897fb737cf8556248b28fa3790063a3021356922">897fb73</a>)</li>
<li><strong>Schema:</strong> update to v31.0.3 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/532e23feba95e96d7cb5e73dc2cf9bbf5ec05203">532e23f</a>)</li>
<li><strong>Schema:</strong> update to v31.1.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/190b91b4c10f3d7d0ebbff763da4747237400f36">190b91b</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/compare/v6.0.3...v6.0.4">6.0.4</a> (2024-05-28)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> update to v31.0.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/2357be2cc46aa78d8fa8e3f6c1bc846be9ab3973">2357be2</a>)</li>
<li><strong>Schema:</strong> update to v31.0.1 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/9c93b3b72c05f23df806af63eccb71b8b4dd6cb4">9c93b3b</a>)</li>
<li><strong>Schema:</strong> update to v31.0.2 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/29c14c232dfaec063a80b7c503fde25589d6b127">29c14c2</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/compare/v6.0.2...v6.0.3">6.0.3</a> (2024-05-28)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Closure:</strong> added closure type and date (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/d09f2f08beb8be28191d311b6bb5e224ddbfbf82">d09f2f0</a>)</li>
<li><strong>Mitigation chart:</strong> start date and completed checkmark position (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/66f11cc074da7dd1e4bb06d6996f189ea840f0e9">66f11cc</a>)</li>
<li><strong>Schema:</strong> update to v30.2.1 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/f8f3a0aa20d854e7017238588173a96d3f1a5dbf">f8f3a0a</a>)</li>
<li><strong>Schema:</strong> update to v30.3.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/ba45f134adf66c208b8dfec5dbc77d1b8b0b6474">ba45f13</a>)</li>
<li><strong>Schema:</strong> update to v30.3.1 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/74348998a2ce12b9eea74b7c9fdd525a440977af">7434899</a>)</li>
<li><strong>Schema:</strong> update to v30.3.2 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/987d94a15d4479d7ae2286a65f687fde558b12c1">987d94a</a>)</li>
<li><strong>Top Risks:</strong> added filters for each quarter (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/ae178bb05d970220b6445b0007ef3445134243d8">ae178bb</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/compare/v6.0.1...v6.0.2">6.0.2</a> (2024-05-20)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> update to v30.1.3 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/a02bda141d01d488b90dc9e43a47845e0660ea5d">a02bda1</a>)</li>
<li><strong>Schema:</strong> update to v30.1.4 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/ef200aaec9764958d8aeaf8234dcb4a4bef91842">ef200aa</a>)</li>
<li><strong>Schema:</strong> update to v30.2.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/ee7b1ad7c37f6cb2e358c8c5a940dc91a3ac60ce">ee7b1ad</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/compare/v6.0.0...v6.0.1">6.0.1</a> (2024-05-10)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> update to v30.1.2 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/701169191829d8663147a3994e5e6db962cfdf91">7011691</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/compare/v5.0.0...v6.0.0">6.0.0</a> (2024-05-09)</h1>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Risk manage:</strong> add stakeholders to risk (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/432be0e428f2cdb8d1f5d0c934ef2bd25a2b94ef">432be0e</a>)</li>
<li><strong>Schema:</strong> update to v30.1.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/12ec73083834f9cc98cd94dbfd4c9e7fc6f409ec">12ec730</a>)</li>
<li><strong>Schema:</strong> update to v30.1.1 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/2c9f2623f34ebc2a4b13373a01e9c717e75d9eda">2c9f262</a>)</li>
</ul>
<h3>Features</h3>
<ul>
<li><strong>Schema:</strong> update to v29.0.1 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/ce7d41be2772493d2a614b7a13cfea6d7ce9f337">ce7d41b</a>)</li>
<li><strong>Schema:</strong> update to v30.0.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/1348bd62e47734e301e3e4be2583b0d20fc76d08">1348bd6</a>)</li>
</ul>
<h3>BREAKING CHANGES</h3>
<ul>
<li><strong>Schema:</strong> no user facing updates massive typings changes</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/compare/v4.0.6...v5.0.0">5.0.0</a> (2024-04-29)</h1>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/5bb03401955703cfca82443a11f37c7582669925">5bb0340</a>)</li>
</ul>
<h3>Features</h3>
<ul>
<li><strong>Types:</strong> update includes adding submodule access for api types (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/50fac7e552bdd0773508d25982af3d07e9e6607f">50fac7e</a>)</li>
</ul>
<h3>BREAKING CHANGES</h3>
<ul>
<li><strong>Types:</strong> schema updates</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/compare/v4.0.5...v4.0.6">4.0.6</a> (2024-04-24)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Mitigations:</strong> updated chart for completed tasks (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/1f2c828032f166ef7d2885be5d8f63fe0535381d">1f2c828</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/compare/v4.0.4...v4.0.5">4.0.5</a> (2024-04-24)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> Update to 27.1.1 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/a58606976f431bcc10c72cc72ff0f56cf70ffb31">a586069</a>)</li>
<li><strong>Schema:</strong> Update to 28.0.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/fc606e7ddf799cd1bca1305a5afd671a147768e9">fc606e7</a>)</li>
<li><strong>Schema:</strong> update to v27.1.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/822e74e53776e7f8bae2c38967d8baab8ba1c2f4">822e74e</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/compare/v4.0.3...v4.0.4">4.0.4</a> (2024-04-19)</h2>
<h3>Bug Fixes</h3>
<ul>
<li>ci whitespace (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/ce174769f29d5bfb4abe2e72358cf16f980a73e7">ce17476</a>)</li>
<li>Dockerfile package registry (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/bcf1297a6a3a19cdd2dde4d462119f1e46a24847">bcf1297</a>)</li>
<li>update image repository (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/4af65267ab769b8c837ae4fdc4c682aa5363bc82">4af6526</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/compare/v4.0.2...v4.0.3">4.0.3</a> (2024-04-19)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Schema:</strong> update to v27.0.2 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/5951f0bcb4a2c2c852c9b43901359a3c965d80e3">5951f0b</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/compare/v4.0.1...v4.0.2">4.0.2</a> (2024-04-18)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/4fe2299556be299c9e34614f21355c05261fbfcc">4fe2299</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/compare/v4.0.0...v4.0.1">4.0.1</a> (2024-04-17)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/7ededa2d263aee8db256bd72cfb60a71718c9b2b">7ededa2</a>)</li>
<li><strong>Schema:</strong> update to v27.0.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/9edcc9b4802eb51354e4329c9363e093ad3af63a">9edcc9b</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/compare/v3.4.0...v4.0.0">4.0.0</a> (2024-04-16)</h1>
<h3>Features</h3>
<ul>
<li><strong>Schema:</strong> update to v25.0.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/752d38cd80e94ba35442c43171bdf1f882b59250">752d38c</a>)</li>
<li><strong>Schema:</strong> update to v26.0.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/00a998233f98b038c6d9d45ede532826d4f4e99a">00a9982</a>)</li>
</ul>
<h3>BREAKING CHANGES</h3>
<ul>
<li><strong>Schema:</strong> No visual changes.</li>
<li><strong>Schema:</strong> releaseStatus move from Record to Revision level</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/compare/v3.3.0...v3.4.0">3.4.0</a> (2024-04-12)</h1>
<h3>Features</h3>
<ul>
<li><strong>Schema:</strong> update to v22.0.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/0569024728fb896196c0e70af1664c6ab5a61eb7">0569024</a>)</li>
<li><strong>Schema:</strong> update to v24.0.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/12813c9b23f86ecf707619a35a3228b9db08eb61">12813c9</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/compare/v3.2.5...v3.3.0">3.3.0</a> (2024-04-09)</h1>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Risk:</strong> Dependency and security updates. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/e3dd033fb6b6512208fd113875db6915388a7daf">e3dd033</a>)</li>
<li><strong>Risk:</strong> export updates (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/253b2b9a1b79d2f22eeea65fa9497d5b4c690b77">253b2b9</a>)</li>
<li><strong>Schema:</strong> update to v20.2.2 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/bcb5bdd164ccdcadfffb1552271b826b62b9da78">bcb5bdd</a>)</li>
</ul>
<h3>Features</h3>
<ul>
<li><strong>Schema:</strong> update to v21.0.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/8b46e2e6d3a9f4bb4e3c4b035a1b544667ca295d">8b46e2e</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/compare/v3.2.4...v3.2.5">3.2.5</a> (2024-03-28)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Risk:</strong> manage page and top risk report updates (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/1896e4de0806dd20c19985cd2b74c20922d560df">1896e4d</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/compare/v3.2.3...v3.2.4">3.2.4</a> (2024-03-28)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Export and manage:</strong> updates (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/95f0bcf19fe348856e8a93fddf2497e499876f4d">95f0bcf</a>)</li>
<li><strong>Risk:</strong> corrects display issue with score on risk creations (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/265450054b679c50610fe777dfb04575adf112c4">2654500</a>)</li>
<li><strong>Risk:</strong> risk scores in the moderate (yellow) zone are shown in black to improve contrast (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/876e5c41f438f1e6aa8bc79c150bd3bb438f2d65">876e5c4</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/compare/v3.2.2...v3.2.3">3.2.3</a> (2024-03-28)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Risk Home View:</strong> Moved charts to separate component; (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/ea271032c1d7df221a17f968ebdcc4ff96b0d36c">ea27103</a>)</li>
<li><strong>Risk:</strong> Charts! (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/7d99972174505cc8bb40e8c429ef01d9ed146173">7d99972</a>)</li>
<li><strong>Risk:</strong> updated Search view to function on the query string (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/304b9e5ac712a276b7f4c129673ccba6dd723adc">304b9e5</a>)</li>
<li><strong>Schema:</strong> update to v20.1.1 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/74063010485da61b1c3c64e301180b384bc8031c">7406301</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/compare/v3.2.1...v3.2.2">3.2.2</a> (2024-03-27)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Reporting:</strong> update Top Risks report (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/3d09fa254cbe32d726014187513f39b7b2153d16">3d09fa2</a>)</li>
<li><strong>Risk:</strong> create view/modal update (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/6f976e31cde80110b4648227a210e902e8710a96">6f976e3</a>)</li>
<li><strong>RiskExport:</strong> Update top risk table. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/bcc3fa6cbd1bf01583a88e0ad67619c212ba3fd7">bcc3fa6</a>)</li>
<li><strong>RiskManageView:</strong> Show green loader when loading instead of fetching data error. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/e5d2d8b8e30623372c2161324dbdffd71ba0803f">e5d2d8b</a>)</li>
<li><strong>Schema:</strong> update to v20.1.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/00aa36a66b3de59ecba4ea6e99755f5062474240">00aa36a</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/compare/v3.2.0...v3.2.1">3.2.1</a> (2024-03-26)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Risk:</strong> Update reportability (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/5704b5b55a32eb530d47c2f46e2085364a8d9c85">5704b5b</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/compare/v3.1.1...v3.2.0">3.2.0</a> (2024-03-26)</h1>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Home:</strong> update top 10 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/0dba4121f4a8c73d5b865b773283ab37c554db3f">0dba412</a>)</li>
<li><strong>Risk:</strong> updates (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/60abb20e5c959c6c3febbf2390e8b76ab016868d">60abb20</a>)</li>
</ul>
<h3>Features</h3>
<ul>
<li><strong>Archive Risk report:</strong> new report (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/231756b7116427623b37134a9acf7da1184945c2">231756b</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/compare/v3.1.0...v3.1.1">3.1.1</a> (2024-03-25)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Risk:</strong> Manage page updates (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/a9f3e8f7900a9a19203a1402715e11def1d2a699">a9f3e8f</a>)</li>
<li><strong>Schema:</strong> update to v20.0.1 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/5804c878e7d77c85626539d3618195023b9a8b36">5804c87</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/compare/v3.0.7...v3.1.0">3.1.0</a> (2024-03-25)</h1>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Migitation edit:</strong> update form validity (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/db88d90a0c105bff63a3387e31973524dd5ff0a3">db88d90</a>)</li>
<li><strong>Mitigation:</strong> update view (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/b2ccc3fbe0240be8fa8ce685bb7ed3694b7f06af">b2ccc3f</a>)</li>
<li><strong>Risk mitigations:</strong> update (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/4b2b8120dd664163b039f49aeab11a62b72d4862">4b2b812</a>)</li>
</ul>
<h3>Features</h3>
<ul>
<li><strong>Risk manage:</strong> aligned actions to right of title in main card (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/69daa9199feefc8420b96ea9ca8c7b907d1783f9">69daa91</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/compare/v3.0.6...v3.0.7">3.0.7</a> (2024-03-25)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Mitigation steps:</strong> update hides/shows fields appropriately (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/77b9490262610125e22d14b20b5f89d8a157dc7e">77b9490</a>)</li>
<li><strong>Reporting:</strong> update Top Risks report (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/92606311ca3da6a1414e8b5b89371ad2e7ca9fba">9260631</a>)</li>
<li><strong>Risk:</strong> Risk edit/Mitigation step edit added optional comment. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/2a1c9d643e577160b57aff03254f04bb844d7f24">2a1c9d6</a>)</li>
<li><strong>Risk:</strong> search view updates (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/a64f28e357d68e420b3602a607905e8eaa4f7270">a64f28e</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/compare/v3.0.5...v3.0.6">3.0.6</a> (2024-03-25)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Risk:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/dcebc24df6c88d536b83ae12d568a9f63805f380">dcebc24</a>)</li>
<li><strong>Risk:</strong> update to risk score (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/05b4d4eea1fbc894bf6ee3613820f8a0a5365bc3">05b4d4e</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/compare/v3.0.4...v3.0.5">3.0.5</a> (2024-03-25)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Mitigation:</strong> Update ux. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/329a4f5da9953bc56e88e9453a63b1cd8af9f66c">329a4f5</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/compare/v3.0.3...v3.0.4">3.0.4</a> (2024-03-25)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Risk:</strong> logic update (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/430e421aff71adaeefae91eddd76cf9d881bb91b">430e421</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/compare/v3.0.2...v3.0.3">3.0.3</a> (2024-03-25)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Risk edit:</strong> update to not make a comment (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/9d6565c90292cfb5c4ea0635282009446cab3ae3">9d6565c</a>)</li>
<li><strong>UI:</strong> Updates (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/ff746a4700799b09413526438fdf367f5ea39e09">ff746a4</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/compare/v3.0.1...v3.0.2">3.0.2</a> (2024-03-25)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Risk:</strong> update manage page with archived functionality (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/4323cd746b54335153536037d95899948187b646">4323cd7</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/compare/v3.0.0...v3.0.1">3.0.1</a> (2024-03-25)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Risk:</strong> update includes new fields to edit (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/6317955674ca012c3a6d806142b241f44c144137">6317955</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/compare/v2.4.6...v3.0.0">3.0.0</a> (2024-03-25)</h1>
<h3>Bug Fixes</h3>
<ul>
<li><strong>RISK:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/3b3ca07e622f49484ef973e9384b0ad6e6b6762f">3b3ca07</a>)</li>
</ul>
<h3>Features</h3>
<ul>
<li><strong>Schema:</strong> update to v20.0.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/edbbd429e691e547a2af134cbc12eead761a2983">edbbd42</a>)</li>
</ul>
<h3>BREAKING CHANGES</h3>
<ul>
<li><strong>Schema:</strong> Update to risks and mitigations</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/compare/v2.4.5...v2.4.6">2.4.6</a> (2024-03-25)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Risk:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/461c2765eae7f9539e0cb63232f60c07aa0d7891">461c276</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/compare/v2.4.4...v2.4.5">2.4.5</a> (2024-03-22)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/ace203ff935c73e56710bff0676dc719459d51ba">ace203f</a>)</li>
<li><strong>Risk score:</strong> updates (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/8ee3e8a216adebaf6431cb0205dd22117f610ef8">8ee3e8a</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/compare/v2.4.3...v2.4.4">2.4.4</a> (2024-03-22)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Top nav:</strong> update with a creation + sign (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/80b38ed97cf0fd0dd1a5324afc571bdd6a41233a">80b38ed</a>)</li>
<li><strong>Top Risks:</strong> update (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/aba54891232276039832bb18e096cd297cfa1db4">aba5489</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/compare/v2.4.2...v2.4.3">2.4.3</a> (2024-03-22)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Orb Notes:</strong> correct comment modal issues (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/6cb92390c80cdcdd80b628b5784dc0aab13b5f16">6cb9239</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/compare/v2.4.1...v2.4.2">2.4.2</a> (2024-03-21)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Mitigation Step:</strong> Mitigation steps are editable (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/a39917d0618620d80bdab312751ffccdeeeb4874">a39917d</a>)</li>
<li><strong>Risk create:</strong> update correct visual issues (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/65ccf1e38431f234ba10dbcbe38c56eb339969d0">65ccf1e</a>)</li>
<li><strong>Risk edit:</strong> update modal to be in line with the create (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/b7c811f0dc96af6abe72641421b1a334fe8e3bfc">b7c811f</a>)</li>
<li><strong>Risk:</strong> get all risks on the search view (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/d7b2f44b42a32105236d240ee1c5bf39b54dab98">d7b2f44</a>)</li>
<li><strong>Risk:</strong> update to Manage view (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/f93cce86de5bc0a4d3fc0da84e92fe4ca7cd6542">f93cce8</a>)</li>
<li><strong>Search:</strong> update fetches all items and correct filters (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/e243f062c8b9826202261f61368dd4e40ac38dc9">e243f06</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/compare/v2.4.0...v2.4.1">2.4.1</a> (2024-03-21)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Risk:</strong> Updated tabs to extend the full width (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/5ee26c644c3b817b103b88664a8e50267865e260">5ee26c6</a>)</li>
<li><strong>Risk:</strong> wording changes (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/18fbd36b22bcceb4e9d510ac98e876e3a66393c7">18fbd36</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/compare/v2.3.1...v2.4.0">2.4.0</a> (2024-03-21)</h1>
<h3>Bug Fixes</h3>
<ul>
<li><strong>risk export:</strong> added both charts to export (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/a9ac03ced27d694e135398c14e0f71e21a51211b">a9ac03c</a>)</li>
<li><strong>Risk manage:</strong> Mitigation table headers updated; Risk score uses monospace font in table; (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/d4a222806b974c6dc5e8f08ec54eb8094e73a3f6">d4a2228</a>)</li>
</ul>
<h3>Features</h3>
<ul>
<li><strong>Change log:</strong> initial pass at the change log tab (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/e4817a8529750a66e0d743a0e0c0d62fc84da720">e4817a8</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/compare/v2.3.0...v2.3.1">2.3.1</a> (2024-03-20)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Risk:</strong> tighten up margins (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/1f968c7e5f604ef16dc93a9373c883022d001d21">1f968c7</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/compare/v2.2.0...v2.3.0">2.3.0</a> (2024-03-20)</h1>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Link:</strong> Update Risk manage (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/24352228dc6faa1c2dfae9bd6eb83b0e06025712">2435222</a>)</li>
<li><strong>Risk:</strong> update manage page side metadata area (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/26695f6207dcaeddf08e5e99650aa1d024be38c8">26695f6</a>)</li>
<li><strong>Risk:</strong> updates (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/92a5ae0bc711649dad408f96dfa206d5baa29689">92a5ae0</a>)</li>
</ul>
<h3>Features</h3>
<ul>
<li><strong>Risk:</strong> enable edit functionality (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/4e0f92eabfc8a08a54e56c78130877517607e1f5">4e0f92e</a>)</li>
<li><strong>Risk:</strong> update to how numbering works (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/cecff575227ff8b4026d86dd14d7adcf6b25071e">cecff57</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/compare/v2.1.0...v2.2.0">2.2.0</a> (2024-03-20)</h1>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Risk Manage:</strong> early out when no mitigations (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/38d23524e91d034656086dff665967d85219e384">38d2352</a>)</li>
</ul>
<h3>Features</h3>
<ul>
<li><strong>PDF:</strong> exporting data ftw! (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/e718ba3130066110b6964de013b30014f9709688">e718ba3</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/compare/v2.0.0...v2.1.0">2.1.0</a> (2024-03-19)</h1>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Orb notes:</strong> bettering (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/df2f47fecf5a57b27e1c5395f56f3ec0ab596cb5">df2f47f</a>)</li>
<li><strong>Risk Manage View:</strong> Replaced if/then with cause/effect in risk manage view and risk create modal; Replaced footer URLs (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/7965f4f5de93ed0e70a81bfebda8110055cd4ca7">7965f4f</a>)</li>
<li><strong>Risk:</strong> create risk update (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/1072d5ab5f2f72f2764d7e1c99c04cd97b710e20">1072d5a</a>)</li>
<li><strong>Schema:</strong> update to v19.0.2 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/6251afc7d9278621d9eff99758e997d79e7fbe5f">6251afc</a>)</li>
</ul>
<h3>Features</h3>
<ul>
<li><strong>ORB notes:</strong> ability to view latest changes to Risk and comment on them (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/e06f6eb0e4f41e8b19dd3bc1dff690a3ff1c44e4">e06f6eb</a>)</li>
</ul>
<h1><a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/compare/v1.0.2...v2.0.0">2.0.0</a> (2024-03-18)</h1>
<h3>Bug Fixes</h3>
<ul>
<li><strong>COSMIC:</strong> Dependency update. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/8c419638e4b5e823e1911406f5179cb555296652">8c41963</a>)</li>
<li><strong>Risk:</strong> new entity (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/49100e10609f90f7b1e05df67b352d22b49d5662">49100e1</a>)</li>
<li><strong>Risk:</strong> side navigation updates. (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/b9fa41da6ec102d870de92e1c124897ea5041bcb">b9fa41d</a>)</li>
<li><strong>Schema:</strong> update to v18.1.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/47f9edbfa7df4b9c037143b8e807e185ace95a19">47f9edb</a>)</li>
<li><strong>Side nav:</strong> removed profile link (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/173c1ef3c440e6158d6115ee0da79a9f38816ff5">173c1ef</a>)</li>
<li><strong>Wonderus:</strong> amazing fixes to please the standards deity (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/fe3d8b92de22f9dd116c671fad9d4db0916b0254">fe3d8b9</a>)</li>
</ul>
<h3>Features</h3>
<ul>
<li><strong>Risk:</strong> Add ability to create a Mitigation step (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/2cf4f7ab2a2ae44b0ffeebb7c618e3e688e69517">2cf4f7a</a>)</li>
<li><strong>Schema:</strong> update to v19.0.0 (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/63b3ec80e8af28ee4a6d971e96353f7cce30c1cd">63b3ec8</a>)</li>
</ul>
<h3>BREAKING CHANGES</h3>
<ul>
<li><strong>Schema:</strong> Comments/Links new api</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/compare/v1.0.1...v1.0.2">1.0.2</a> (2024-03-12)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Risk:</strong> added tabs to manage page (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/commit/96c0e1a3227eabe349abc67345b5246e9c39f989">96c0e1a</a>)</li>
</ul>
<h2><a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/frontend/compare/v1.0.0...v1.0.1">1.0.1</a> (2024-03-12)</h2>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Risk manage:</strong> added risk manage view (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/frontend/commit/e048ed09cbd7a61ae89f8c1cbdad33d9acca045e">e048ed0</a>)</li>
<li><strong>risk:</strong> single risk export to pdf (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/frontend/commit/186a6eba9ea5cb79ae0cf79d8c7d95cbb0fb082b">186a6eb</a>)</li>
</ul>
<h1>1.0.0 (2024-03-12)</h1>
<h3>Bug Fixes</h3>
<ul>
<li><strong>Risks:</strong> added Risks view (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/frontend/commit/b4bee75e661fda62e7411461da5a99bc24624db5">b4bee75</a>), closes <a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/frontend/issues/8">#8</a></li>
<li><strong>Risks:</strong> added Risks view (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/frontend/commit/4fa09a6821cb50fb3b1270dd25ea3f0b358c9449">4fa09a6</a>)</li>
<li><strong>Risks:</strong> fixed widths on filter inputs (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/frontend/commit/4d8642eba131886fd9237d759aa48d5729fb8b05">4d8642e</a>)</li>
<li><strong>Risks:</strong> wired up create, fetching all risks (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/frontend/commit/6f10ab85dce6ec8e816cbabe9e078648e0db3cd6">6f10ab8</a>)</li>
<li><strong>types:</strong> bump nasa_UI (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/frontend/commit/d1c4056c86b7d0430939cda8b3ad5e644877d5fb">d1c4056</a>)</li>
</ul>
<h3>Features</h3>
<ul>
<li><strong>Create Risk:</strong> Create risk modal (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/frontend/commit/a84ae48d5b78e414f8734200f1c1fb373e980d40">a84ae48</a>)</li>
<li><strong>Initial commit:</strong> first commit (<a href="https://gitlab.com/mri-technologies/cosmic/nasa/risk/frontend/commit/84bc47ebb3957a1f0b6cb971aab9a0f0c680674a">84bc47e</a>)</li>
</ul>
</section></template><script>export default {components:{}}</script>